<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post"  data-vv-scope="frmGoal" novalidate="novalidate">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('sidebar.goals-year') }}</h5>
            <div class="row">
                <custom-input
                  v-model="goal.year"
                  name="goal.year"
                  :label="this.$i18n.t('form.goal.year')"
                  type="text"
                  rootClassName="col-lg-2 col-md-4 form-group mb-4"
                  inputClassName="md-form-control"
                  :max-length="25"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('goal.year')">
                </custom-input>
                <div class="col-lg-2 col-md-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.goal.idFrequency.label') }}</label>
                  <v-select v-model="goal.idFrequency" class="md-form-control"
                    :disabled="isEdit" name="name" label="name" :clearable="false"
                    :searchable="true"  @search="frequencyFetchOptions"
                    :options="frequencyList" @input="onSelectFrequency" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <custom-input
                  v-model="percentage"
                  :label="this.$i18n.t('form.goal.percentage')"
                  type="text"
                  rootClassName="col-lg-2 col-md-4 col-sm-6 form-group mb-4"
                  inputClassName="md-form-control"
                  :max-length="3">
                </custom-input>
              </div>
              <div class="row">
                <div  class="col-12" v-for="(modality, index) in goal.modalities"  :key="index" >
                  <h4 class="card-subtitle mb-3">
                    {{ $t('form.goal.section.' + index) }}
                  </h4>
                  <div class="row">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <td>Indicator</td>
                          <td style="text-align: right;" v-for="(periodIndex) in (12 / goal.idFrequency.intervals)" :key="periodIndex">
                            {{ $t('form.goal.idFrequency.' + getFrequencyLabel + '.' + periodIndex) }}
                          </td>
                          <td style="text-align: right;" v-if="getFrequencyLabel !==  'yearly'">Total (Ano)</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(indicator, indicatorIndex) in modality.goals" :key="indicatorIndex" v-show="indicator.show">
                          <td>
                            {{ indicator.type.name }}
                          </td>
                          <td v-for="(goal, goalIndex) in indicator.frequency_goal" class="editable" :key="goalIndex" >
                            <custom-input
                              v-model="goal.value"
                              name="goal.value"
                              type="text"
                              rootClassName="form-group no-margin"
                              inputClassName="md-form-control no-margin"
                              :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                              :error="submitted && errors.first('goal.value')"
                              :indicator = "indicator"
                              :goals = "modality.goals"
                              :updateValue = "updateValue">
                            </custom-input>
                          </td>
                          <td class="text-right align-middle" v-if="getFrequencyLabel !==  'yearly'">
                            {{ (calculateTotal(indicator)) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveGoalYear">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancelGoal">
          Cancelar
        </button>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import GoalService from '@/services/GoalService'

export default {
  name: 'GoalForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.goal.title') + ' - %s'
    }
  },
  data () {
    return {
      //  Selected Values (Form)
      goal: {
        year: (new Date()).getFullYear(),
        value: null
      },
      percentage: 10,
      isEdit: false,
      formErrors: {},
      isLoading: true,
      fullPage: true,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/goal',
      // List of options to select on select input
      goalList: [],
      frequencyList: [],
      submitted: false

    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput
  },
  // Apply filters to local filter
  created () {
    let _this = this
    this.isEdit = this.$route.name === 'GoalYearEdit'

    let promises = [
      GoalService.getFrequency()
    ]

    // If is edit populate values
    let year = this.$route.params.year

    if (year) {
      promises.push(GoalService.getGoalYear(year))
    } else {
      promises.push(GoalService.getGoalNew(null, year))
    }

    Promise.all(promises).then((values) => {
      let res2 = values[0]
      _this.frequencyList = res2.data.data

      // If is edit populate values
      if (year) {
        let goals = values[1]
        _this.goal = goals.data.data

        if (goals.data.data.send_email === true) {
          _this.goal.send_email = 1
        } else {
          _this.goal.send_email = 0
        }
      } else {
        let goals = values[1]
        _this.goal = goals.data.data
      }

      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  computed: {
    getFrequencyLabel () {
      let intervals = this.goal.idFrequency.intervals
      let type = ''

      if (intervals === 3) {
        type = 'quarter'
      } else if (intervals === 1) {
        type = 'monthly'
      } else if (intervals === 12) {
        type = 'yearly'
      }

      return type
    }
  },
  methods: {
    calculateTotal (indicator) {
      let goals = indicator.frequency_goal

      let total = 0
      let countItems = 0
      for (let i = 0; i < goals.length; i++) {
        countItems++

        let value = goals[i].value

        if (value.toString().indexOf('.') !== -1 && value.toString().indexOf(',') !== -1) {
          value = value.toString().replace('.', '')
          value = value.toString().replace(',', '.')
        } else {
          value = value.toString().replace(',', '.')
        }

        total = total + parseFloat(value)
      }

      if (indicator.type.is_percent) {
        total = total / countItems
      }

      return this.showNumber(total)
    },
    updateValue (indicator, goals) {
      let values = indicator.frequency_goal
      let name = indicator.type.name.split(' ')
      let porcentagem = parseFloat(this.percentage) * 0.01 + 1

      for (let i = 0; i < goals.length; i++) {
        if (goals[i].type.name.includes(name[0]) && !goals[i].type.name.includes(name[1]) && name[1] === '(Embarcados)') {
          let target = goals[i].frequency_goal
          for (let x = 0; x < values.length; x++) {
            if (values[x].value !== 0) {
              let temp = values[x].value.replace('.', '')
              temp = temp.toString().replace(',', '.')
              temp = (parseFloat(temp) * porcentagem).toFixed(2)
              if (temp % 1 !== 0) {
                target[x].value = temp.toString().replace('.', ',')
              } else {
                target[x].value = parseInt(temp)
              }
            }
          }
        }
      }
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onSelectFrequency (val) {
      let sections = 12 / val.intervals
      for (let modality in this.goal.modalities) {
        for (let modalityGoal in this.goal.modalities[modality].goals) {
          // Remove previous settings
          this.goal.modalities[modality].goals[modalityGoal].frequency_goal = []

          for (let section = 1; section <= sections; section++) {
            let frequencyGoalObject = {}
            frequencyGoalObject.period = section
            frequencyGoalObject.value = 0.0
            frequencyGoalObject.percent = 0.0

            this.goal.modalities[modality].goals[modalityGoal].frequency_goal.push(frequencyGoalObject)
          }
        }
      }
      this.goal.idFrequency = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_goal: object.id } }).href
    },
    /**
     * Triggered when the frequency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    frequencyFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      GoalService.getFrequency(filters).then(res => {
        me.frequencyList = res.data.data
        loading(false)
      })
    },
    onCancelGoal (e) {
      var _this = this
      _this.$router.push({ name: 'GoalYearIndex' })
    },
    onSaveGoalYear (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('frmGoal').then(valid => {
        if (valid) {
          _this.isLoading = true

          let goalData = {
            year: _this.goal.year,
            idFrequency: _this.goal.idFrequency.id,
            modalities: _this.goal.modalities
          }

          if (this.$router.currentRoute.name === 'GoalYearEdit') {
            goalData['edit'] = 'true'
          }

          GoalService.getGoalByYearSave(goalData).then(response => {
            _this.$router.push({ name: 'GoalYearIndex' })
          }).catch(err => {
            if (err.code === 400) {
              _this.displayErrors(err.errors)
            }
            _this.isLoading = false
          })
        } else {
          _this.$util.scrollToFirstError()
          _this.onLoading(false)
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
